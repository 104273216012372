var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClose),expression:"handleClose"}],staticClass:"mt-12 px-6 max-h-200 overflow-y-scroll base-scrollbar pb-2"},[(_vm.isLoading)?_c('AuditLoader'):_vm._e(),(!_vm.isLoading && !_vm.auditsError)?_c('div',[_c('div',{staticClass:"flex pb-2 flex-col w-full border-b border-neutral-50 bg-white"},[_c('p',{staticClass:"h4"},[_vm._v(_vm._s(_vm.auditHeader))])]),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"px-3 bg-white"},[_c('AuditSummary',{attrs:{"summary":_vm.auditSummary}})],1),_c('div',{staticClass:"sticky top-0 bg-white z-10 flex-row w-full justify-evenly"},[_c('div',{staticClass:"pt-4 mx-4 flex"},[_c('div',{staticClass:"w-1/2 text-center flex flex-row justify-center gap-2 pb-3",class:{
              'border-b-2 border-bb-brand-purple text-bb-brand-purple': _vm.selectedView === 'issues',
              'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
                _vm.selectedView !== 'issues',
            },on:{"click":function($event){_vm.selectedView = 'issues'}}},[_c('ic-alert-circle'),_c('p',{staticClass:"h5"},[_vm._v("Issues")])],1),_vm._m(0),_c('div',{staticClass:"w-1/2 text-center flex flex-row justify-center gap-2 pb-3",class:{
              'border-b-2 border-bb-brand-purple text-bb-brand-purple': _vm.selectedView === 'categories',
              'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
                _vm.selectedView !== 'categories',
            },on:{"click":function($event){_vm.selectedView = 'categories'}}},[_c('ic-alert-circle'),_c('p',{staticClass:"h5 font-medium"},[_vm._v("Categories")])],1)])]),_c('div',[(_vm.selectedView === 'issues')?_c('div',[_c('AuditCheckList',{attrs:{"checks":_vm.auditDetails.checks,"readonly":""}})],1):_vm._e(),(_vm.selectedView === 'categories')?_c('div',{staticClass:"h-1/2"},[_c('AuditCategories',{attrs:{"checks":_vm.auditDetails.checks,"category-scores":_vm.auditSummary.score_by_category,"readonly":""}})],1):_vm._e()])])]):_vm._e(),(_vm.auditsError && !_vm.isLoading)?_c('div',[_vm._m(1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2 py-1"},[_c('div',{staticClass:"w-px h-full bg-neutral-50"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col items-center text-center my-20"},[_c('img',{staticClass:"mb-6",attrs:{"src":"/content/images/brightbid/bidbot-broken.svg"}}),_c('p',{staticClass:"h3"},[_vm._v("Failed to load the audit...")]),_c('p',[_vm._v("Please try to refresh the page and try again.")])])
}]

export { render, staticRenderFns }